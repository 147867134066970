<template>
	<div>
		<h2>白银区学校片区划分查询</h2>
		<!-- <h2>幼儿园片区划分查询</h2> -->
		<div id="map" ref="map" style="width: 100%; height: 100vh;"></div>
		<div id="popup-content"></div>
	</div>
</template>

<script>
	import {
		Feature,
		Map,
		View
	} from 'ol';
	import TileLayer from 'ol/layer/Tile';
	import {
		Vector as VectorLayer
	} from 'ol/layer';
	import {
		Vector as VectorSource,
		XYZ
	} from 'ol/source';
	import GeoJSON from 'ol/format/GeoJSON';
	import {
		Style,
		Fill,
		Stroke,
		Text
	} from 'ol/style';
	import * as proj from "ol/proj";
	import Overlay from 'ol/Overlay';
	import {
		Point,
		Text as TextGeom
	} from 'ol/geom';
	import {
		createBox,
		createText
	} from 'ol/extent';
	import {toStringHDMS} from 'ol/coordinate';
	import axios from 'axios';
	export default {
		name: 'MapComponent',
		data() {
			return {
				map: null,
				vectorSource: null,
				genJson: []
			};
		},
		mounted() {
			this.initMap();
			this.loadData();
			//this.loadGeoJSON();
		},
		methods: {
			initMap() {
				this.map = new Map({
					target: this.$refs.map,
					layers: [
						new TileLayer({
							source: new XYZ({
								url: 'https://webst01.is.autonavi.com/appmaptile?style=9&x={x}&y={y}&z={z}',
							}),
						}),
					],
					view: new View({
						center: proj.fromLonLat([104.17355, 36.54411]),
						zoom: 10,
						minZoom: 8,
						maxZoom: 18,
						extent: proj.get('EPSG:3857').getExtent(),
					}),
				});

				this.vectorSource = new VectorSource();
				const vectorLayer = new VectorLayer({
					source: this.vectorSource,
					style: this.styleFunction,
				});
				this.map.addLayer(vectorLayer);
			},
			loadData() {
				// axios.get('http://125.74.234.168:8086/wxapi/list')
				axios.get('/wxapi/list')
				// axios.get('http://localhost:8086/wxapi/list')
					.then(response => {
						console.log(response.data.data)
						this.loadGeoJSON(response.data.data)
					})
					.catch(error => {
						console.error('获取失败', error)
					});
			},
			loadGeoJSON(data) {
				// 假设后端传来的多组 GeoJSON 数据保存在 this.geoJsonData 中
				const format = new GeoJSON();
				// console.log(format)
				for (const geoJson of data) {
					const features = format.readFeatures(geoJson.jwJson);
					for (const feature of features) {
						// 创建多边形样式
						const style = new Style({

							fill: new Fill({
								// color: randomColor,
								color: geoJson.areaColor,
							}),
							stroke: new Stroke({
								color: geoJson.lineColor,
								width: 1,
							}),
							text: new Text({
								text: geoJson.schoolName,
								// text: geoJson.code,
								fill: new Fill({
									color: '#000'
								}),
								stroke: new Stroke({
									color: '#fff',
									width: 2
								}),
								// rotation: geoJson.schoolName.rotation,
								// rotation: geoJson.code,
							}),
						});
						feature.setStyle(style);
					}

					this.vectorSource.addFeatures(features);
				}
				this.map.getView().fit(this.vectorSource.getExtent());
				const popup = new Overlay({
					element: document.getElementById('popup-content'),
					positioning: 'bottom-center',
					stopEvent: false,
					offset: [0, -10],
				});
				this.map.addOverlay(popup);
				this.map.on('click', (event)=> {
					// console.log(event)
					this.map.forEachFeatureAtPixel(event.pixel, function(feature) {
						const coordinate = event.coordinate;
						// console.log(coordinate)
						const hdms = toStringHDMS(coordinate);
						// console.log(hdms)
						const content = document.getElementById('popup-content');
						content.innerHTML = `<p>Coordinates: ${hdms}</p>`;
						// content.html = `<p>Coordinates: ${hdms}</p>`;
						// popup.setPosition(coordinate);
						// popup.setElement(content);
						// popup.set('feature', feature);
					});
				});
			},
			styleFunction(feature) {
				return feature.getStyle();
			},
		},
	};
</script>
<style>
	h2 {
		z-index: 999;
	}
</style>