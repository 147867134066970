<template>
  <div id="app">
    <!-- <Area /> -->
	<SetArea />
  </div>
</template>

<script>
import Area from "@/pages/area";
import SetArea from "@/pages/area/setArea"
export default {
  name: 'App',
  components: {
    Area,
	SetArea
  }
}
</script>

<style>
	#app{
		text-align: center;
	}
</style>
