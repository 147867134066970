// const base_url='https://school.jyxwhy.cn'
// const base_url='https://kindergarten.jyxwhy.cn.cn'
// const base_url='http://localhost:8081'
// const base_url='http://61.178.147.105:8086'
export const myRequest=(options)=>{
	return new Promise((resolve,reject)=>{
		uni.request({
			url:base_url+options.url,
			// 不传默认为get
			method:options.method || 'GET',
			// data不传默认给一个空对象
			data:options.data || {},
			// header:options.method==='POST'?'application/x-www-form-urlencoded':{},
			success:(res)=>{
				/* if(res.data.status!==0){
					return uni.showToast({
						title:'获取数据失败'
					})
				} */
				resolve(res)
			},
			fail:(err)=> {
				uni.showToast({
					title:'请求接口失败'
				})
				// 返回错误消息
				reject(err)
			}
		})
	})
}