import Vue from 'vue'
import App from './App.vue'
import VueAMap from 'vue-amap'
import 'ol/ol.css'
import TileLayer from 'ol/layer/Tile'

Vue.use(VueAMap)
// 将请求接口的js挂载到全局
import {myRequest} from './pages/util/api.js'
Vue.prototype.$myRequest=myRequest

Vue.config.productionTip = false

VueAMap.initAMapApiLoader({
  key: '72e666a07c98365a9c00ab3647878038',
  plugin: ['AMap.Geocoder'],
})

new Vue({
  render: h => h(App),
}).$mount('#app')
