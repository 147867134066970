<template>
  <div>
    <div id="map" ref="map" style="width: 100%; height: 400px;"></div>
    <button @click="startDrawing">开始绘制</button>
    <button @click="sendDrawnArea">保存</button>
  </div>
</template>

<script>
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import { Vector as VectorLayer } from 'ol/layer';
import {Vector as VectorSource, XYZ} from 'ol/source';
import { Draw } from 'ol/interaction';
import { Style, Stroke } from 'ol/style';
import * as proj from "ol/proj";

import axios from 'axios';

export default {
  data() {
    return {
      map: null,
      vectorSource: null,
      drawInteraction: null,
      drawnAreas: [],
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      this.map = new Map({
        target: this.$refs.map,
        layers: [
          new TileLayer({
            source: new XYZ({
              url: 'https://webst01.is.autonavi.com/appmaptile?style=9&x={x}&y={y}&z={z}',
            }),
          }),
        ],
        view: new View({
          center: proj.fromLonLat([104.17355, 36.54411]),
          zoom: 10,
          minZoom: 8,
          maxZoom: 18,
          extent: proj.get('EPSG:3857').getExtent(),
        }),
      });

      this.vectorSource = new VectorSource();
      const vectorLayer = new VectorLayer({
        source: this.vectorSource,
        style: new Style({
          stroke: new Stroke({
            color: 'red',
            width: 2,
          }),
        }),
      });
      this.map.addLayer(vectorLayer);
    },
    sendDrawnArea() {
      const geoJson = this.convertToGeoJSON()
      console.log(geoJson)
	  // base_url="http://localhost:8080/area/schoolmap"
      axios.post('http://localhost:8080/area/schoolmap/api/saveDrawnArea', geoJson)
          .then(response => {
            console.log('区域保存成功');
          })
          .catch(error => {
            console.error('保存区域失败', error)
          });
    },
    startDrawing() {
      this.drawInteraction = new Draw({
        source: this.vectorSource,
        type: 'Polygon',
      });
      this.map.addInteraction(this.drawInteraction);
    },
    convertToGeoJSON() {
      const features = this.vectorSource.getFeatures();
      const geoJson = {
        type: 'FeatureCollection',
        features: [],
      };

      for (const feature of features) {
        const geometry = feature.getGeometry();
        const coordinates = geometry.getCoordinates()[0];

        const featureJson = {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [coordinates],
          },
          properties: {},
        };

        geoJson.features.push(featureJson);
      }

      return geoJson;
    },
  },
};
</script>
